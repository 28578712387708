<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Üyeler"
      icon="UsersIcon"
      :showLoading="show"
      :actionCollapse="true"
      :actionRefresh="true"
      :actionNewAdd="true"
      :collapsed="true"
      @refresh="refreshStop('cardAction')"
      @newAdd="handlerNewAdd($event)"
    >
      <!-- actionCollapse true ise filtre kısmı calısırız -->
      <template v-slot:filtrele>
        <b-row class="my-1 mx-25">
          <b-col cols="12" md="4">
            <b-form-input v-model="searchTerm" class="mr-1 rounded-0" placeholder="Arama..." />
          </b-col>
          <b-col cols="12" md="4">
            <v-select
              v-model="filter.kategor_k_no"
              :options="kategoriler"
              :reduce="(kategoriler) => kategoriler.k_no"
              label="title"
              :clearable="true"
              placeholder="Kategori Göre"
              @input="handlerFilter"
            />
          </b-col>
          <b-col cols="12" md="4">
            <v-select
              v-model="filter.statu"
              :options="statuData"
              :reduce="(statuData) => statuData.statu"
              label="title"
              :clearable="false"
              placeholder="Statu"
              @input="handlerFilter"
            />
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'save_date'">
              {{ props.row.save_date | momentFull }}
            </span>
            <span v-else-if="props.column.field === 'ad_soyad'" class="d-flex">
              <b-avatar :src="avatarShow(props.row.profil)" class="mx-1" />
              <span>
                <strong class="text-danger">{{ props.row.ad }} {{ props.row.soyad }}</strong> <br />
                <strong class="text-primary">{{ props.row.kullanici_adi }}</strong>
              </span>
            </span>
            <span v-else-if="props.column.field === 'kullanici_adi'">
              <span>{{ props.row.kullanici_adi }}</span>
            </span>
            <span v-else-if="props.column.field === 'e_mail'">
              <span>{{ props.row.e_mail }}</span>
            </span>
            <span v-else-if="props.column.field === 'konum'">
              <span>{{ props.row.sehir }}</span> <br />
              <span>{{ props.row.ulke }}</span> <br />
              <span>{{ props.row.posta_kodu }}</span> <br />
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-form-checkbox
                class="custom-control-info"
                v-model="props.row.statu"
                switch
                @change="handlerStatu(props.row.k_no, $event)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="WifiIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="WifiOffIcon" />
                </span>
              </b-form-checkbox>
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-button-group size="sm" class="pb-25">
                <b-button
                  variant="primary"
                  class="rounded-0"
                  :to="{
                    name: 'uye-detay',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button variant="warning" class="rounded-0" @click="handlerUpdate(props.row)">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button variant="danger" class="rounded-0" @click="handlerRemove(props.row.k_no)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-button-group>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <v-select
                  v-model="pageLength"
                  :options="perPageOptions"
                  :clearable="false"
                  class="invoice-filter-select"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="pl-2"> Toplam Kayıt : {{ count }}</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </template>
    </b-card-actions>
    <uye-tanimla v-model="openModal" :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import { mapGetters } from 'vuex';
import UyeTanimla from './uyeTanimla.vue';
export default {
  components: {
    BCardActions,
    VueGoodTable,
    vSelect,
    UyeTanimla,
  },
  data() {
    return {
      show: false,
      openModal: false,
      closeModal: false,
      updateData: {},
      pageLength: 10,
      perPageOptions: [10, 25, 50, 100],
      kategoriler: [],
      columns: [
        {
          label: 'Tarih',
          field: 'save_date',
          width: '10%',
        },
        {
          label: 'Ad Soyad',
          field: 'ad_soyad',
        },
        {
          label: 'E Mail',
          field: 'e_mail',
          width: '18%',
        },
        {
          label: 'Konum',
          field: 'konum',
          width: '18%',
        },
        {
          label: 'Statu',
          field: 'statu',
          width: '5%',
          tdClass: 'text-right',
        },
        {
          label: 'İşlemler',
          field: 'action',
          width: '5%',
        },
      ],
      count: 0,
      rows: [],
      filter: {
        kullanici_turu: null,
        statu: true,
      },
      searchTerm: '',
      statuData: [
        {
          statu: true,
          title: 'Aktif',
        },
        {
          statu: false,
          title: 'Pasif',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getUyeler']),
    avatarShow() {
      return (val) => {
        if (val != null) {
          const base_url =
            process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
          return `${base_url}uploads/uyeprofil/${val}`;
        }
      };
    },
  },
  created() {
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      const data = await this.$store.dispatch('uyeListele');
      this.count = data.data.count;
      // await this.$store.dispatch('freeKullanicilarListele', this.filter);
      this.rows = await this.getUyeler;
      this.show = false;
    },

    async handlerStatu(k_no, event) {
      if (k_no != null) {
        await axiosIns.post('/uyeler/uye-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Güncelleme`,
                icon: 'Trash2Icon',
                variant: 'success',
                text: `Güncelleme başarılı.`,
              },
            });
          }
        });
      }
    },
    handlerNewAdd() {
      this.updateData = {};
      this.openModal = true;
    },
    handlerUpdate(val) {
      this.updateData = val;
      this.openModal = true;
    },
    handlerFilter() {
      this.handlerGetData();
    },
    refreshStop(event) {
      this.handlerGetData();
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('uyeSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
  },
  watch: {
    async closeModal(val) {
      if (val == false) {
        this.openModal = false;
        this.updateData = {};
        this.rows = [];
        this.rows = await this.$store.getters.getUyeler;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
