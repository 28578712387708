<template>
  <div>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Rezervasyon Kayıt No">
          <b-input-group>
            <b-input-group-prepend is-text> REZ </b-input-group-prepend>
            <b-form-input class="rounded-0" v-model="filter.k_no" placeholder="220216150235YG" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="2">
        <b-form-group label="Filtre tarih türü">
          <v-select
            v-model="filter.tarih_turu"
            :options="tarihTurleri"
            :reduce="(tur) => tur.value"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3">
        <div class="mb-50">Rezervasyon Tarihi</div>
        <div class="d-flex align-items-center">
          <flat-pickr v-model="filter.bas_tarih" placeholder="Bu tarihle" class="form-control rounded-0" :config="config" />
          <span class="px-25">ile</span>
          <flat-pickr v-model="filter.bit_tarih" placeholder="Bu tarih arası" class="form-control rounded-0" :config="config" />
        </div>
      </b-col>
      <b-col cols="12" md="3">
        <b-form-group label="Önem Derecesi">
          <v-select
            v-model="filter.siralama"
            :options="Siralama"
            :reduce="(sira) => sira.value"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Rezervasyon Statu">
          <v-select
            v-model="filter.statu"
            :options="Statuler"
            :reduce="(statu) => statu.value"
            label="title"
            multiple
            :clearable="true"
            class="invoice-filter-select d-block"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3">
        <b-form-group label="Ödeme yöntemine göre">
          <v-select
            v-model="filter.odeme"
            :options="OdemeYontemleri"
            :reduce="(odeme) => odeme.value"
            label="title"
            multiple
            :clearable="true"
            class="invoice-filter-select d-block"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button squared variant="success" block @click="$emit('handlerFilter', true)"> Filtere Uygula</b-button>
      </b-col>
      <b-col>
        <b-button squared variant="danger" block @click="$emit('handlerFilterClear', true)"> İptal Et</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import vSelect from 'vue-select';
export default {
  components: {
    flatPickr,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    config: {
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    },

    Siralama: [
      { title: 'Yeni Rezervasyonlar Üstte', value: 1 },
      { title: 'Seyehat tarihi', value: 2 },
      { title: 'Gönderim tarihi', value: 3 },
    ],

    tarihTurleri: [
      { title: 'Rez. tarihine göre', value: 1 },
      { title: 'İşlem tarihine göre', value: 2 },
    ],

    Statuler: [
      { title: 'Onay Bekleyenler', value: 1 },
      { title: 'Değişiklik İsteği', value: 2 },
      { title: 'Ücret İade İsteği', value: 3 },
      { title: 'Onaylananlar', value: 4 },
      { title: 'İptal Edilenler', value: 5 },
    ],

    OdemeYontemleri: [
      { title: 'Kapıda Ödeme', value: 1 },
      { title: 'Havale EFT', value: 2 },
      { title: 'Kredi Kartı', value: 3 },
    ],
  }),
  methods: {
    triggerNewAdd(event) {
      this.$emit('newAdd', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
