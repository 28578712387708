<template>
  <b-card-actions
    ref="cardAction"
    title="Rezervasyonlar"
    icon="LayersIcon"
    :showLoading="show"
    :actionCollapse="true"
    :actionRefresh="true"
    :actionNewAdd="true"
    :collapsed="true"
    @refresh="refreshStop('cardAction')"
    @newAdd="handlerNewAdd($event)"
  >
    <template v-slot:filtrele>
      <div class="p-2">
        <filtrele :filter="filter" @handlerFilter="handlerFilters($event)" @handlerFilterClear="handlerFilterClear()" />
      </div>
    </template>
    <template v-slot:body>
      <listele :rows="reservations" :count="count" />
    </template>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Filtrele from './component/filtrele.vue';
import Listele from './component/listele.vue';

export default {
  components: {
    BCardActions,
    Filtrele,
    Listele,
  },
  data: () => ({
    show: false,
    filter: {
      k_no: null,
      tarih_turu: 1,
      bas_tarih: null,
      bit_tarih: null,
      siralama: 1,
      statu: [],
      odeme: [],
    },
    count: 0,
    reservations: [],
  }),
  created() {
    this.handlerRezervasyonlar();
  },
  methods: {
    async handlerRezervasyonlar() {
      this.show = true;
      await this.$store.dispatch('rezervasyonListele', this.filter).then((res) => {
        this.count = res.data.count;
        this.reservations = res.data.data;
      });
      this.show = false;
    },
    handlerFilters(event) {
      this.handlerRezervasyonlar();
    },
    refreshStop(event) {
      this.handlerRezervasyonlar();
    },
    handlerFilterClear() {
      this.filter = {
        k_no: null,
        tarih_turu: 1,
        bas_tarih: null,
        bit_tarih: null,
        siralama: 1,
        statu: [],
        odeme: [],
      };
      this.handlerRezervasyonlar();
    },
  },
};
</script>

<style lang="scss" scoped></style>
