<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="false"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'save_date'">
          <router-link
            :to="{
              name: 'rezervasyon-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            {{ props.row.save_date | momentFull }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'tarih'" class="pr-25">
          <router-link
            :to="{
              name: 'rezervasyon-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            {{ props.row.tarih | moment }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'k_no'" class="pr-25">
          <router-link
            :to="{
              name: 'rezervasyon-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            {{ props.row.k_no }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'urun_adi'">
          <span>{{ props.row.urun['tr'].urun_adi | str_limit(30) }}</span>
        </span>
        <span v-else-if="props.column.field === 'pax'">
          <b-badge fill variant="primary" v-b-tooltip.hover.top="'Yetişkin'" class="rounded-0 mr-25">
            {{ props.row.yetiskin_pax }}
          </b-badge>
          <b-badge fill variant="danger" v-b-tooltip.hover.top="'Çocuk'" class="rounded-0 mr-25">
            {{ props.row.cocuk_pax }}
          </b-badge>
          <b-badge fill variant="success" v-b-tooltip.hover.top="'Bebek'" class="rounded-0 mr-25">
            {{ props.row.bebek_pax }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'toplam'">
          <b-badge variant="success" v-b-tooltip.hover.top="'Seçilen Kur Fiyat Toplam'" class="rounded-0">
            {{ props.row.secilen_kur_toplam | cur }}
          </b-badge>
          <b-badge class="rounded-0" variant="warning">
            {{ props.row.secilen_kur }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'odeme'">
          <b-badge class="rounded-0" :style="`background-color:${odemeColor(props.row.odeme_yontemi)};`">
            {{ odemeText(props.row.odeme_yontemi) }}
          </b-badge>
          <b-badge
            class="rounded-0"
            :variant="props.row.odeme_durum ? 'success' : 'danger'"
            v-b-tooltip.hover.top="props.row.odeme_durum ? 'Tahsil edildi' : 'Beklemede'"
          >
            {{ props.row.odeme_durum ? 'T' : 'B' }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-badge class="rounded-0" :style="`background-color:${statuColor(props.row.statu)};`">
            {{ statuText(props.row.statu) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="primary"
              class="rounded-0"
              :to="{
                name: 'rezervasyon-detay',
                params: { k_no: props.row.k_no },
              }"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <v-select
              v-model="pageLength"
              :options="perPageOptions"
              :clearable="false"
              class="invoice-filter-select w-50"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="pl-2"> Toplam Kayıt : {{ count }}</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
export default {
  components: {
    VueGoodTable,
    vSelect,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    count: [Number],
  },
  computed: {
    statuText() {
      const statusText = {
        1: 'Onay Bekliyor',
        2: 'Değişiklik İsteği',
        3: 'Ücret İade İsteği',
        4: 'Onaylandı',
        5: 'İptal Edilenler',
      };

      return (status) => statusText[status];
    },
    statuColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#ffc107',
        3: '#DA4E8F',
        4: '#20c997',
        5: '#ea5455',
      };

      return (status) => statusColor[status];
    },
    odemeText() {
      const statusText = {
        1: 'Kapıda Ödeme',
        2: 'Havale EFT',
        3: 'Kredi Kartı',
      };

      return (status) => statusText[status];
    },
    odemeColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#20c997',
        3: '#ff9f43',
      };

      return (status) => statusColor[status];
    },
  },
  data: () => ({
    pageLength: 25,
    perPageOptions: [10, 25, 50, 100],
    columns: [
      {
        label: 'İşlem Tarihi',
        field: 'save_date',
        width: '10%',
      },
      {
        label: 'Rez. Tarihi',
        field: 'tarih',
        width: '5%',
      },
      {
        label: '#Rez No',
        field: 'k_no',
        width: '12%',
      },
      {
        label: 'Ürün Adı',
        field: 'urun_adi',
      },
      {
        label: 'Pax',
        field: 'pax',
        width: '7%',
      },
      {
        label: 'Toplam',
        field: 'toplam',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Ödeme',
        field: 'odeme',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '3%',
      },
    ],
  }),
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
